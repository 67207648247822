<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="800">
        <v-card flat class="card">
            <v-toolbar outlined flat>
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>

                                <v-col cols="12">
                                    <div class="mx-auto text-center">
                                        <v-avatar rounded size='200'>
                                            <v-img :src="item.photoUrl == '' ? require('@/assets/images/photo.svg') : $url + item.photoUrl">
                                            </v-img>
                                        </v-avatar>
                                        <br />
                                        <v-btn @click="openFilePicker" color="primary" text class="text-none mr-2" :loading="uploading" large>
                                            <h3>رفع صورة</h3>
                                        </v-btn>
                                        <!-- Added @change event to trigger the upload function -->
                                        <input ref="uploader" class="d-none" type="file" accept="jpg, jpeg, png" @change="handleFileUpload">
                                    </div>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">الإسم الثلاثي <span class="required">*</span></h3>
                                    <v-text-field v-model="item.fullName" placeholder="الإسم الثلاثي" :rules="[$global.state.required()]" required filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">اسم المستخدم <span class="required">*</span></h3>
                                    <v-text-field v-model="item.userName" placeholder="يفضل ان يكون باللغة الانكليزية" :rules="[$global.state.required()]" required filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6" v-if="$store.state.itemDetails.dialogType == 'add'">
                                    <h3 class="mb-2">كلمة المرور <span class="required">*</span></h3>
                                    <v-text-field v-model="item.password" placeholder="**************" :rules="[$global.state.required()]" required filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">البريد الالكتروني </h3>
                                    <v-text-field v-model="item.email" placeholder="email@business_domain.com" required filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2"> رقم الهاتف <span class="required">*</span></h3>
                                    <v-text-field v-model="item.phoneNumber" v-mask="$phoneMask" dir="ltr" placeholder="+964 ### ### ####" :rules="[$global.state.required()]" required filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2"> العنوان </h3>
                                    <v-text-field v-model="item.address" placeholder="العنوان" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">المحافظة <span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.governorateId" @change="getAreas()" :rules="[$global.state.required()]" clearable item-text="name" item-value="id" :items="$global.state.governorates" class="mr-1" placeholder="المحافظة" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">المنطقة<span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.areaId" :search-input.sync="search" :rules="[$global.state.required()]" v-debounce:500ms="getAreas" clearable item-text="name" item-value="id" :items="areas" class="mr-1" placeholder="المنطقة" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                                <v-col cols="12" md="6" v-if="($route.path == '/warehouse_manager') || ($route.path == '/employee')">
                                    <h3 class="mb-2">المخزن<span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.warehouseId" :rules="[$global.state.required()]" v-debounce:500ms="getWarehouses" clearable item-text="name" item-value="id" :items="warehouses" class="mr-1" placeholder="المخزن" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                                <v-col cols="12" md="6" v-if="($route.path != '/merchant')">
                                    <h3 class="mb-2">المكتب<span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.officeId" :rules="[$global.state.required()]" v-debounce:500ms="getOffices" clearable item-text="name" item-value="id" :items="offices" class="mr-1" placeholder="المكتب" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                                <v-col cols="12" md="6" v-if="($route.path == '/warehouse_manager') || ($route.path == '/employee')">
                                    <h3 class="mb-2">الصلاحية<span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.authGroupId" :rules="[$global.state.required()]" v-debounce:500ms="authGroups" clearable item-text="name" item-value="id" :items="authGroups" class="mr-1" placeholder="الصلاحية" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                                <aMoneyField v-if="($route.path == '/delivery_delegater')" v-model="item.deliveryCost" title="اجور التوصيل" :cols="12" :md="6" suffix="د.ع" :fieldTitle="true" />

                                <aMoneyField v-if="($route.path == '/merchant')" v-model="item.deliveryCost" title="اجور التوصيل داخل بغداد" :cols="12" :md="6" suffix="د.ع" :fieldTitle="true" />
                                <aMoneyField v-if="($route.path == '/merchant')" v-model="item.DeliveryCostForGovernates" title="اجور التوصيل للمحافظات" :cols="12" :md="6" suffix="د.ع" :fieldTitle="true" />

                            </v-row>
                            <!-- <pre dir="ltr">
                            {{ item }}
                            </pre> -->
                            <br/>
                            <v-divider/>
                            <v-btn color="primary" outlined @click="addField()" class="mr-4 my-4">
                                اضافة حقل
                            </v-btn>
                            <v-row v-for="(attach,index) in item.attachments" :key="index" v-show="item.attachments.length > 0">
                                <v-col cols="12">
                                    <v-divider/>
                                </v-col>
                                <v-col cols="6">
                                    <h3>المرفق</h3>
                                    <v-file-input @change="onFileChanged(index)" loader-height="10" filled v-model="item.attachments[index].file" :loading="uploading" color="primary" counter label="المرفقات" append-icon="fi fi-rr-file" outlined :show-size="1000">
                                        <template v-slot:selection>
                                            <v-chip v-if="index < 2" color="primary accent-4" dark label small>
                                                {{ truncateString(item.attachments[index].url.split('/').pop()) }}
                                            </v-chip>

                                            <!-- <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 ">
                                                    +{{ files.length - 2 }} ملف(ات)
                                                </span> -->
                                        </template>
                                    </v-file-input>
                                </v-col>

                                <v-col cols="6">
                                    <h3>العنوان</h3>
                                    <v-text-field v-model="item.attachments[index].name" filled outlined />
                                </v-col>
                                <v-col cols="12" >
                                    <v-avatar tile size="auto">
                                        <v-img :src="$url+'/'+item.attachments[index].url"/>
                                    </v-avatar>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid || uploading" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            files: [],
            uploadedFiles: [],
            item: {},
            search: '',
            valid: false,
            areas: [],
            warehouses: [],
            offices: [],
            authGroups: [],
            uploading: false,
        }
    },

    created() {
        this.reset()
        this.$eventBus.$on('fill-fields', () => {

            console.log(this.$store.state.itemDetails)
            this.reset()
            this.item = this.$store.state.itemDetails
            this.item.governorateId = this.$store.state.itemDetails.governorate.id
            this.item.areaId = this.$store.state.itemDetails.area.id
            this.item.officeId = this.$store.state.itemDetails.office.id
            this.item.attachments = this.$store.state.itemDetails.attachments
            this.item.warehouseId = this.$store.state.itemDetails.warehouse.id
            this.item.authGroupId = this.$store.state.itemDetails.authGroups.id

            this.getAreas()
            this.getWarehouses()
            this.getOffices()
            this.getAuthGroupss()
        })
        this.getAuthGroupss()

        this.getAreas()
        this.getWarehouses()
        this.getOffices()
    },

    methods: {

        truncateString(str, length = 15) {
        if (str.length > length) {
            return str.substring(0, length) + '...';
        }
        return str;
        },

        addField() {
            this.item.attachments.push({
                "name": "",
                "url": "",
                "file": null
            })
        },

        async onFileChanged(index) {

            let formData = new FormData();

            // Add the files to formData
            formData.append("files", this.item.attachments[index].file);

            this.uploading = true
            try {
                // Replace with your API endpoint
                let response = await this.$http.post('/UploadFiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 200) {
                    console.log("Files uploaded successfully.");
                    console.log(response.data)
                    // this.uploadedFiles = response.data;
                    this.item.attachments[index].url = response.data[0]
                    // Handle success
                } else {
                    console.error("Error during file upload: " + response.status);
                    // Handle error
                }
            } catch (error) {
                console.error("Exception during file upload:", error);
                // Handle exception
            } finally {
                this.uploading = false
            }
        },

        reset() {
            this.item = {
                "fullName": "",
                "userName": "",
                "password": null,
                "email": "",
                "phoneNumber": "",
                "address": "",
                "userTypeId": '',
                "authGroupId": '',
                "governorateId": 1,
                "areaId": '',
                "warehouseId": '',
                "photoUrl": '',
                "deliveryCost": 0,
                "DeliveryCostForGovernates": 0,
                "attachments": [{
                    "name": "",
                    "url": "",
                    "file": null
                }]
            }
        },

        submit() {
            this.item.phoneNumber = this.item.phoneNumber.replace(/,/g, "")
            this.item.userTypeId = this.$route.meta.type
            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        addItem() {
            this.$global.state.loading = true
            let data = this.item;

            // for (let i = 0; i < array.length; i++) {
            //     const element = array[i];

            // }

            this.$http.post(`User`, data).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`User/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        openFilePicker() {
            // Programmatically trigger the click event of the hidden input
            this.$refs.uploader.click();
        },

        handleFileUpload(event) {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                this.uploading = true;

                const formData = new FormData();
                formData.append('files', file);

                this.$http.post('UploadFiles', formData)
                    .then(response => {
                        this.item.photoUrl = response.data[0]; // Update the photoUrl with the new URL
                    })
                    .catch(error => {
                        console.error('Error uploading file:', error);
                    })
                    .finally(() => {
                        this.uploading = false;
                    });
            }
        },

        async getAreas() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=10000&GovernorateId=${this.item.governorateId}`)
                this.areas = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getWarehouses() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=100&GovernorateId=${this.item.governorateId}`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getOffices() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Office`)
                this.offices = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getAuthGroupss() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`AuthGroup`)
                this.authGroups = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
